<template>
  <div class="row">
    <school-form :editing-existing="true" :header-text="'Edit a Nebraska School'" :submit-text="'Submit Changes'" :id-school="idSchool"/>
  </div>


</template>

<script>
import SchoolForm from "@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolForm";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "SchoolEdit",
  components: {SchoolForm},
  props: {
    idSchool: Number
  },
  setup (props, context) {

  }
}
</script>

<style scoped>

</style>